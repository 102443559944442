<template>
  <v-app>
    <v-container>
      <v-card>
        <v-card-title>Менеджеры</v-card-title>
        <v-card-actions>
          <v-btn @click="editUser()">Добавить</v-btn>
          <v-btn
              :small="$vuetify.breakpoint.width < 480"
              @click="filters.deleted=filters.deleted? 0:1;updateTable()"
              :loading="loadingTable"
              class="v-btn-info">{{ ['Активные', 'Заблокированные'][filters.deleted] }}
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <v-data-table
              hover
              :items="users"
              :headers="usersHeader"
              item-key="n_id"
              :loading="loadingTable"
              :footer-props="{'items-per-page-options':[-1]}"
              no-data-text="Менеджеров нет"
              loading-text="Загрузка..."
              mobile-breakpoint="0"
          >
            <template v-slot:item="{item, index}">
              <tr style="cursor: pointer" @click="editUser(item.n_id)">
                <td>{{ index + 1 }}</td>
                <td>{{ item.login }} <Rate v-if="item.rate" small :value="item.rate"/></td>
                <td>{{ ['Активный', 'Заблокирован'][Number(item.deleted)] }}</td>
                <td>{{ item.fname }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <User ref="user"/>
  </v-app>
</template>

<script>
import User from "@/views/users/User";
import Rate from "../../components/Rate";

export default {
  name: "Users",
  components: {
    Rate,
    User,
  },
  data() {
    return {
      users: [],
      usersHeader: [
        {text: '#'},
        {text: 'Логин'},
        {text: 'Статус'},
        {text: 'Имя'},
      ],
      filters: {deleted: 0},
      loadingTable: true,
    }
  },
  mounted() {
    this.updateTable(false)
  },
  methods: {
    updateTable(reload = true) {
      if (reload) this.loadingTable = true;
      this.$store.state.server.request('users/get', this.filters, (data) => {
        this.users = data.response
        this.loadingTable = false;
        if (!reload) setTimeout(() => {
          if (this.$router.currentRoute.path === '/users') this.updateTable(false)
        }, 5000)
      })
    },
    editUser(user_id = null) {
      const modal = this.$refs.user;
      modal.user = {n_id: user_id};
      modal.open()
    }
  }
}
</script>

<style scoped>

</style>
